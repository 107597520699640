import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainCarteDuSite from '../sections/MainCarteDuSite';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';

export default function CarteDuSite() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Plan du Site | Rock Your Net Auxerre</title>
                <meta name="description" content="Plan du site Rock Your Net. Retrouvez tous nos services : création de sites web, e-commerce, applications, SEO et marketing digital à Auxerre. Navigation simple vers toutes nos pages." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="plan du site, navigation, services web Auxerre, Rock Your Net Bourgogne, sitemap, structure du site" />
                <link rel="canonical" href="https://rockyournet.com/carte-du-site" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Plan du Site | Rock Your Net Auxerre" />
                <meta property="og:description" content="Découvrez la structure complète de notre site et accédez facilement à tous nos services web à Auxerre : création de sites, applications, SEO et marketing digital." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/carte-du-site" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Plan du Site Rock Your Net Auxerre" />
                <meta name="twitter:description" content="Explorez notre site et trouvez rapidement les informations sur nos services web à Auxerre. Structure complète et navigation intuitive." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Plan du Site Rock Your Net" />

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Plan du Site Rock Your Net",
                        "url": "https://rockyournet.com/carte-du-site",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Plan du site détaillé de Rock Your Net, agence web à Auxerre.",
                        "isPartOf": {
                            "@type": "ProfessionalService",
                            "name": "Rock Your Net",
                            "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Auxerre",
                                "addressRegion": "Bourgogne-Franche-Comté",
                                "addressCountry": "FR"
                            },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5714"
                            }
                        },
                        "mainContentOfPage": {
                            "@type": "WebPageElement",
                            "hasPart": [
                                {
                                    "@type": "SiteNavigationElement",
                                    "name": "Accueil",
                                    "url": "https://rockyournet.com"
                                },
                                {
                                    "@type": "SiteNavigationElement",
                                    "name": "Services",
                                    "url": "https://rockyournet.com/Services"
                                },
                                {
                                    "@type": "SiteNavigationElement",
                                    "name": "Produits",
                                    "url": "https://rockyournet.com/Produits"
                                },
                                {
                                    "@type": "SiteNavigationElement",
                                    "name": "Créations",
                                    "url": "https://rockyournet.com/Créations"
                                },
                                {
                                    "@type": "SiteNavigationElement",
                                    "name": "Contact",
                                    "url": "https://rockyournet.com/Contact"
                                },
                                {
                                    "@type": "SiteNavigationElement",
                                    "name": "À Propos",
                                    "url": "https://rockyournet.com/A-propos"
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainCarteDuSite />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};