import React from 'react';
import { Box, Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainAccueil from '../sections/MainAccueil';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';

export default function Accueil() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <Box overflow="hidden" position="relative" minH="100vh" w="100%">
            <Helmet>
                {/* Titre et Meta description optimisés */}
                <title>Rock Your Net | Création de Sites Web, Applications & SEO à Auxerre</title>
                <meta name="description" content="Rock Your Net, agence web à Auxerre spécialisée en création de sites web, développement d'applications et optimisation SEO. Plus de 10 ans d'expertise pour booster votre présence en ligne et votre visibilité." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="création site web Auxerre, développement application, agence SEO Auxerre, référencement naturel, création site e-commerce, Rock Your Net, développement web, stratégie digitale, site web professionnel, agence digitale Auxerre" />
                <link rel="canonical" href="https://rockyournet.com/" />

                {/* Open Graph optimisé */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Rock Your Net | Agence Web Auxerre - Création Sites & Applications" />
                <meta property="og:description" content="Agence web à Auxerre spécialisée en création de sites web, développement d'applications et SEO. Transformez votre présence digitale avec Rock Your Net." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card optimisé */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Rock Your Net | Agence Web Auxerre - Création Sites & Applications" />
                <meta name="twitter:description" content="Agence web à Auxerre spécialisée en création de sites web, développement d'applications et SEO. Transformez votre présence digitale avec Rock Your Net." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Rock Your Net - Agence Web Auxerre" />

                {/* Schema.org optimisé */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "ProfessionalService",
                        "@id": "https://rockyournet.com",
                        "name": "Rock Your Net",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "url": "https://rockyournet.com",
                        "telephone": "+33-6-24-31-10-75",
                        "description": "Agence web spécialisée en création de sites web, développement d'applications et SEO. Nous accompagnons les entreprises dans leur transformation digitale.",
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        },
                        "priceRange": "€€",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "addressCountry": "FR"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "47.7979",
                            "longitude": "3.5714"
                        },
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday"
                            ],
                            "opens": "09:00",
                            "closes": "18:00"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services Web",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Création de Sites Web",
                                        "description": "Création de sites web professionnels et e-commerce"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Développement d'Applications",
                                        "description": "Développement d'applications web et mobiles sur mesure"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "SEO",
                                        "description": "Optimisation du référencement naturel"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
                w="100%"
                maxW="100vw"
                overflowX="hidden"
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainAccueil />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </Box>
    );
};