import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainApplicationAuxerre from '../../sections/référencement/MainApplicationAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function ApplicationAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Création Application Web à Auxerre | Solutions Pro | Rock Your Net</title>
                <meta name="description" content="Expert en développement d'applications web sur mesure à Auxerre. Solutions digitales innovantes, applications métier et Progressive Web Apps pour optimiser vos processus d'entreprise. Devis gratuit pour votre projet." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="création application web Auxerre, développement application métier 89, Progressive Web App Auxerre, application sur mesure Yonne, développeur web Auxerre, solution digitale entreprise, application professionnelle" />
                <link rel="canonical" href="https://rockyournet.com/Application-web-auxerre" />

                {/* Open Graph */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Développement Applications Web Auxerre | Rock Your Net" />
                <meta property="og:description" content="Création d'applications web professionnelles à Auxerre. Applications métier sur mesure, PWA et solutions digitales innovantes pour votre entreprise. Expertise locale." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Application-web-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Applications Web Pro Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Développement d'applications web sur mesure à Auxerre. Solutions personnalisées pour optimiser vos processus métier. Expertise locale en développement." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Applications Web Rock Your Net Auxerre" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "SoftwareApplication",
                        "name": "Applications Web Sur Mesure - Rock Your Net Auxerre",
                        "applicationCategory": "BusinessApplication",
                        "operatingSystem": "Web",
                        "offers": {
                            "@type": "Offer",
                            "description": "Développement d'applications web professionnelles sur mesure",
                            "priceSpecification": {
                                "@type": "PriceSpecification",
                                "price": "Sur devis",
                                "priceCurrency": "EUR"
                            }
                        },
                        "provider": {
                            "@type": "ProfessionalService",
                            "name": "Rock Your Net",
                            "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                            "description": "Expert en développement d'applications web professionnelles à Auxerre",
                            "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "addressCountry": "FR"
                        },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5714"
                            },
                            "areaServed": [
                                {
                                    "@type": "City",
                                    "name": "Auxerre"
                                },
                                {
                                    "@type": "AdministrativeArea",
                                    "name": "Yonne"
                                }
                            ],
                            "telephone": "+33624311075",
                            "openingHoursSpecification": {
                                "@type": "OpeningHoursSpecification",
                                "dayOfWeek": [
                                    "Monday",
                                    "Tuesday",
                                    "Wednesday",
                                    "Thursday",
                                    "Friday"
                                ],
                                "opens": "09:00",
                                "closes": "18:00"
                            }
                        },
                        "featureList": [
                            "Applications métier sur mesure",
                            "Progressive Web Apps (PWA)",
                            "Applications web responsive",
                            "Intégration API",
                            "Solutions cloud",
                            "Applications mobiles hybrides"
                        ],
                        "softwareRequirements": "Navigateur web moderne",
                        "targetPlatform": "Web",
                        "applicationSubCategory": "Applications professionnelles"
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainApplicationAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};