import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainDeveloppementWebAuxerre from '../../sections/référencement/MainDeveloppementWebAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function DeveloppementWebAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Développement Web à Auxerre | Applications & Sites Pro | Rock Your Net</title>
                <meta name="description" content="Expert en développement web à Auxerre. Création d'applications web sur mesure, sites professionnels et solutions techniques innovantes. Technologies modernes et développement personnalisé pour votre entreprise." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="développement web Auxerre, développeur web 89, applications sur mesure Yonne, solutions web Auxerre, programmation web locale, React, Node.js, API web" />
                <link rel="canonical" href="https://rockyournet.com/Developpement-web-auxerre" />

                {/* Open Graph */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Développement Web Auxerre | Expert Solutions Web" />
                <meta property="og:description" content="Solutions de développement web professionnelles à Auxerre. Applications sur mesure, sites performants et expertise technique pour votre entreprise." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Developpement-web-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Développement Web Pro Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Expert en développement web à Auxerre. Applications personnalisées et solutions techniques innovantes pour votre entreprise." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Développement Web Rock Your Net Auxerre" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "ProfessionalService",
                        "name": "Rock Your Net - Développement Web Auxerre",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Expert en développement web à Auxerre. Création d'applications sur mesure et solutions techniques innovantes.",
                        "url": "https://rockyournet.com/Developpement-web-auxerre",
                        "telephone": "+33624311075",
                        "priceRange": "€€",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "addressCountry": "FR"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "47.7979",
                            "longitude": "3.5714"
                        },
                        "areaServed": [
                            {
                                "@type": "City",
                                "name": "Auxerre"
                            },
                            {
                                "@type": "AdministrativeArea",
                                "name": "Yonne"
                            }
                        ],
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday"
                            ],
                            "opens": "09:00",
                            "closes": "18:00"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services Développement Web",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Applications Web Sur Mesure",
                                        "description": "Développement d'applications web personnalisées"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Solutions Techniques",
                                        "description": "Développement de solutions web innovantes"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Intégration API",
                                        "description": "Développement et intégration d'APIs"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Maintenance Web",
                                        "description": "Support et maintenance des applications"
                                    }
                                }
                            ]
                        },
                        "knowsAbout": [
                            "Développement web",
                            "Applications sur mesure",
                            "APIs",
                            "React",
                            "Node.js",
                            "Solutions techniques"
                        ]
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainDeveloppementWebAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};