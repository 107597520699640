import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainCommunityManagementAuxerre from '../../sections/référencement/MainCommunityManagementAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function CommunityManagementAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Community Management à Auxerre | Gestion Réseaux Sociaux | Rock Your Net</title>
                <meta name="description" content="Expert en community management à Auxerre. Gestion professionnelle de vos réseaux sociaux : Facebook, Instagram, LinkedIn. Stratégies social media personnalisées et création de contenus engageants pour les entreprises locales." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="community management Auxerre, gestion réseaux sociaux 89, social media Yonne, community manager Auxerre, Facebook entreprise Auxerre, Instagram professionnel, LinkedIn Business, stratégie sociale locale" />
                <link rel="canonical" href="https://rockyournet.com/Community-management-auxerre" />

                {/* Open Graph */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Community Management Auxerre | Expert Réseaux Sociaux" />
                <meta property="og:description" content="Votre expert community management à Auxerre. Gestion professionnelle de vos réseaux sociaux, création de contenus engageants et stratégies social media sur mesure." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Community-management-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Community Management Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Solutions de community management professionnel à Auxerre. Gestion réseaux sociaux, création de contenus et stratégies d'engagement pour votre entreprise." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Community Management Rock Your Net Auxerre" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "ProfessionalService",
                        "name": "Rock Your Net - Community Management Auxerre",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Expert en community management et gestion des réseaux sociaux à Auxerre. Services professionnels de social media management pour les entreprises locales.",
                        "url": "https://rockyournet.com/Community-management-auxerre",
                        "telephone": "+33624311075",
                        "priceRange": "€€",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "addressCountry": "FR"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "47.7979",
                            "longitude": "3.5714"
                        },
                        "areaServed": [
                            {
                                "@type": "City",
                                "name": "Auxerre"
                            },
                            {
                                "@type": "AdministrativeArea",
                                "name": "Yonne"
                            }
                        ],
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday"
                            ],
                            "opens": "09:00",
                            "closes": "18:00"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services Community Management",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Gestion Réseaux Sociaux",
                                        "description": "Gestion professionnelle de vos profils Facebook, Instagram et LinkedIn"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Création de Contenu",
                                        "description": "Création de contenus engageants et adaptés à votre image"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Animation de Communauté",
                                        "description": "Interaction et engagement avec votre audience"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Stratégie Social Media",
                                        "description": "Développement de stratégies personnalisées par réseau"
                                    }
                                }
                            ]
                        },
                        "knowsAbout": [
                            "Community Management",
                            "Réseaux sociaux professionnels",
                            "Marketing digital",
                            "Création de contenu",
                            "Stratégie social media",
                            "Engagement communautaire"
                        ]
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainCommunityManagementAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};