import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainAgenceWebAuxerre from '../../sections/référencement/MainAgenceWebAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function AgenceWebAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Agence Web à Auxerre | Création Sites Internet & SEO | Rock Your Net</title>
                <meta name="description" content="Rock Your Net, votre agence web locale à Auxerre. Experts en création de sites internet, référencement SEO et solutions digitales sur mesure. Accompagnement personnalisé pour les entreprises de l'Yonne." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="agence web Auxerre, création site internet Auxerre, SEO local Auxerre, développement web 89, agence digitale Yonne, webdesign Auxerre, référencement Auxerre" />
                <link rel="canonical" href="https://rockyournet.com/Agence-web-auxerre" />

                {/* Open Graph */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Agence Web Auxerre | Création de Sites & SEO Local" />
                <meta property="og:description" content="Votre partenaire digital à Auxerre. Expertise locale en création de sites internet, référencement SEO et solutions web sur mesure. Devis gratuit pour votre projet." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Agence-web-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Agence Web Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Solutions web complètes à Auxerre : création de sites, SEO local et marketing digital. Expertise locale pour votre succès en ligne." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Agence Web Rock Your Net Auxerre" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "ProfessionalService",
                        "name": "Rock Your Net - Agence Web Auxerre",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Agence web professionnelle à Auxerre spécialisée en création de sites internet, référencement local et solutions digitales sur mesure.",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "addressCountry": "FR"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": 47.7979,
                            "longitude": 3.5714
                        },
                        "url": "https://rockyournet.com/Agence-web-auxerre",
                        "telephone": "+33624311075",
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday"
                            ],
                            "opens": "09:00",
                            "closes": "18:00"
                        },
                        "priceRange": "€€",
                        "areaServed": [
                            {
                                "@type": "City",
                                "name": "Auxerre"
                            },
                            {
                                "@type": "AdministrativeArea",
                                "name": "Yonne"
                            }
                        ],
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services Web Auxerre",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Création de Sites Internet",
                                        "description": "Développement de sites web professionnels sur mesure"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Référencement SEO Local",
                                        "description": "Optimisation pour la visibilité à Auxerre et dans l'Yonne"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Web Design",
                                        "description": "Création d'interfaces modernes et ergonomiques"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Marketing Digital Local",
                                        "description": "Stratégies digitales pour les entreprises d'Auxerre"
                                    }
                                }
                            ]
                        },
                        "knowsAbout": [
                            "Création de sites internet",
                            "Référencement SEO local",
                            "Web design",
                            "Marketing digital",
                            "E-commerce",
                            "Applications web"
                        ]
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainAgenceWebAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};