import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainCommunicationAuxerre from '../../sections/référencement/MainCommunicationAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function CommunicationAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Agence de Communication à Auxerre | Stratégie Web | Rock Your Net</title>
                <meta name="description" content="Votre agence de communication digitale à Auxerre. Experts en stratégie web, réseaux sociaux et marketing digital pour développer votre visibilité. Communication locale et régionale pour les entreprises de l'Yonne." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="agence communication Auxerre, communication digitale 89, stratégie web Yonne, marketing digital Auxerre, réseaux sociaux Auxerre, communication entreprise Bourgogne, agence locale Auxerre" />
                <link rel="canonical" href="https://rockyournet.com/Communication-Auxerre" />

                {/* Open Graph */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Agence Communication Auxerre | Rock Your Net" />
                <meta property="og:description" content="Expertise en communication digitale à Auxerre. Stratégie web, réseaux sociaux et marketing digital pour votre entreprise. Solutions locales adaptées à vos besoins." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Communication-Auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Communication Digitale Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Solutions de communication digitale sur mesure à Auxerre. Stratégie web et réseaux sociaux pour développer votre visibilité locale." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Communication Rock Your Net Auxerre" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "ProfessionalService",
                        "name": "Rock Your Net - Communication Digitale Auxerre",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Agence de communication digitale à Auxerre spécialisée en stratégie web, réseaux sociaux et marketing digital.",
                        "url": "https://rockyournet.com/Communication-Auxerre",
                        "telephone": "+33624311075",
                        "priceRange": "€€",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "addressCountry": "FR"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "47.7979",
                            "longitude": "3.5714"
                        },
                        "areaServed": [
                            {
                                "@type": "City",
                                "name": "Auxerre"
                            },
                            {
                                "@type": "AdministrativeArea",
                                "name": "Yonne"
                            }
                        ],
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday"
                            ],
                            "opens": "09:00",
                            "closes": "18:00"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services Communication Digitale",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Stratégie Digitale",
                                        "description": "Élaboration de stratégies de communication digitale"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Gestion Réseaux Sociaux",
                                        "description": "Animation et gestion de vos réseaux sociaux"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Marketing Digital Local",
                                        "description": "Stratégies marketing ciblées pour Auxerre"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Communication Web",
                                        "description": "Création de contenus et communication en ligne"
                                    }
                                }
                            ]
                        },
                        "knowsAbout": [
                            "Communication digitale",
                            "Marketing digital",
                            "Réseaux sociaux",
                            "Stratégie web",
                            "Content marketing",
                            "Communication locale"
                        ]
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainCommunicationAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};