import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainAgenceDigitaleBourgogne from '../../sections/référencement/MainAgenceDigitaleBourgogne';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function AgenceDigitaleBourgogne() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Agence Digitale en Bourgogne | Création Web & SEO | Rock Your Net</title>
                <meta name="description" content="Rock Your Net, agence digitale basée à Auxerre, rayonnant sur toute la Bourgogne. Experts en création web, référencement SEO et transformation digitale. Solutions sur mesure pour développer votre entreprise régionalement." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="agence digitale Bourgogne, création site web Bourgogne, SEO Bourgogne-Franche-Comté, agence web Auxerre, transformation digitale, marketing digital Yonne, site internet Bourgogne" />
                <link rel="canonical" href="https://rockyournet.com/Agence-digitale-bourgogne" />

                {/* Open Graph */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Agence Digitale Bourgogne | Solutions Web & Marketing Digital" />
                <meta property="og:description" content="Votre partenaire digital en Bourgogne. Expertise locale en création web, SEO et marketing digital depuis Auxerre. Solutions personnalisées pour votre croissance numérique." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Agence-digitale-bourgogne" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Agence Digitale Bourgogne | Rock Your Net" />
                <meta name="twitter:description" content="Expertise digitale au service des entreprises bourguignonnes. Création web, SEO et marketing digital depuis Auxerre pour votre développement régional." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Agence Digitale Bourgogne Rock Your Net" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "ProfessionalService",
                        "name": "Rock Your Net - Agence Digitale Bourgogne",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Agence digitale basée à Auxerre, servant toute la région Bourgogne. Experts en création web, référencement et transformation numérique.",
                        "url": "https://rockyournet.com/Agence-digitale-bourgogne",
                        "telephone": "+33-6-24-31-10-75",
                        "priceRange": "€€",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "addressCountry": "FR"
                        },
                        "geo": {
                            "@type": "GeoCircle",
                            "geoMidpoint": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5714"
                            },
                            "geoRadius": "150000"
                        },
                        "areaServed": [
                            {
                                "@type": "State",
                                "name": "Bourgogne-Franche-Comté"
                            },
                            {
                                "@type": "City",
                                "name": "Auxerre"
                            },
                            {
                                "@type": "City",
                                "name": "Dijon"
                            },
                            {
                                "@type": "City",
                                "name": "Nevers"
                            },
                            {
                                "@type": "City",
                                "name": "Sens"
                            }
                        ],
                        "knowsAbout": [
                            "Création de sites web",
                            "Référencement SEO",
                            "Marketing digital",
                            "E-commerce",
                            "Transformation digitale",
                            "Web design"
                        ],
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services Digitaux en Bourgogne",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Création de Sites Web",
                                        "description": "Développement de sites web professionnels et e-commerce"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Référencement SEO Local",
                                        "description": "Optimisation pour la visibilité régionale"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Marketing Digital",
                                        "description": "Stratégies digitales pour la Bourgogne"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Transformation Numérique",
                                        "description": "Accompagnement digital des entreprises bourguignonnes"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainAgenceDigitaleBourgogne />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};