import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainProduits from '../sections/MainProduits';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';

export default function Produits() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Logiciel de Gestion pour Garages et Concessionnaires Auto | Rock Your Net Auxerre</title>
                <meta name="description" content="Application professionnelle de gestion pour garages automobiles et concessionnaires. Gérez facilement votre stock de véhicules, vos services et votre site web. Solution complète développée à Auxerre." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="logiciel gestion garage, application concessionnaire auto, gestion stock véhicules, site web garage automobile, gestion atelier mécanique, logiciel automobile Bourgogne, gestion garage Auxerre" />
                <link rel="canonical" href="https://rockyournet.com/Produits" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Logiciel de Gestion pour Professionnels de l'Automobile | Rock Your Net" />
                <meta property="og:description" content="Solution complète de gestion pour garages et concessionnaires : stock véhicules, services, site web. Application développée par Rock Your Net à Auxerre." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Produits" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Logiciel de Gestion Auto - Garages et Concessionnaires | Rock Your Net" />
                <meta name="twitter:description" content="Application professionnelle tout-en-un pour la gestion de votre garage : véhicules, services, site web. Développée à Auxerre pour les pros de l'automobile." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Logiciel Gestion Garage - Rock Your Net" />

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "SoftwareApplication",
                        "name": "Application de Gestion pour Garages et Concessionnaires",
                        "applicationCategory": "BusinessApplication",
                        "operatingSystem": "Web",
                        "url": "https://rockyournet.com/Produits",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Logiciel professionnel pour la gestion complète de garages automobiles et concessions : gestion des véhicules, des services, et du site web.",
                        "offers": {
                            "@type": "AggregateOffer",
                            "priceCurrency": "EUR",
                            "lowPrice": "69.00",
                            "highPrice": "89.00",
                            "priceValidUntil": "2025-12-31",
                            "availability": "https://schema.org/InStock"
                        },
                        "featureList": [
                            "Gestion du stock de véhicules",
                            "Suivi des services et interventions",
                            "Site web intégré",
                            "Gestion des rendez-vous",
                            "Interface responsive",
                            "Statistiques et rapports"
                        ],
                        "provider": {
                            "@type": "ProfessionalService",
                            "name": "Rock Your Net",
                            "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Auxerre",
                                "addressRegion": "Bourgogne-Franche-Comté",
                                "addressCountry": "FR"
                            },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5714"
                            },
                            "url": "https://rockyournet.com",
                            "telephone": "+33-6-24-31-10-75",
                            "priceRange": "€€"
                        },
                        "softwareRequirements": "Navigateur web moderne avec connexion internet",
                        "softwareVersion": "2024",
                        "applicationSubCategory": "Gestion automobile"
                    }
                    `}
                </script>
            </Helmet>

            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainProduits />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};