import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainApplication from '../sections/MainApplicationWeb';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';

export default function ApplicationWeb() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Création d'Applications Web sur Mesure à Auxerre | Rock Your Net</title>
                <meta name="description" content="Expert en création d'applications web sur mesure à Auxerre. Applications professionnelles, responsive et évolutives développées avec les technologies les plus performantes. Devis gratuit pour votre projet." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="création application web Auxerre, développement sur mesure, application web responsive, React, Node.js, Progressive Web App, développement application Bourgogne, expertise technique web" />
                <link rel="canonical" href="https://rockyournet.com/Services/ApplicationWeb" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Création d'Applications Web Professionnelles | Rock Your Net Auxerre" />
                <meta property="og:description" content="Expertise en développement d'applications web sur mesure à Auxerre. Solutions performantes et innovantes adaptées à vos besoins spécifiques. Contactez-nous pour votre projet." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Services/ApplicationWeb" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Création d'Applications Web sur Mesure | Rock Your Net Auxerre" />
                <meta name="twitter:description" content="Expertise en développement d'applications web sur mesure. Technologies modernes, solutions évolutives et accompagnement personnalisé à Auxerre." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Création d'Applications Web - Rock Your Net Auxerre" />

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Création d'Applications Web",
                        "name": "Développement d'Applications Web sur Mesure",
                        "url": "https://rockyournet.com/Services/ApplicationWeb",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Service professionnel de création d'applications web sur mesure. Développement avec les technologies modernes, solutions évolutives et accompagnement personnalisé.",
                        "provider": {
                            "@type": "ProfessionalService",
                            "name": "Rock Your Net",
                            "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Auxerre",
                                "addressRegion": "Bourgogne-Franche-Comté",
                                "addressCountry": "FR"
                            },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5714"
                            },
                            "url": "https://rockyournet.com",
                            "telephone": "+33-6-24-31-10-75",
                            "priceRange": "€€"
                        },
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services d'Applications Web",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Applications Web Sur Mesure",
                                        "description": "Développement d'applications web personnalisées selon vos besoins"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Progressive Web Apps",
                                        "description": "Création d'applications web progressives modernes"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Applications Web Responsive",
                                        "description": "Développement d'applications adaptées à tous les appareils"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainApplication />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};