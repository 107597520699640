import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainMentionsLégales from '../sections/MainMentionsLégales';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';

export default function MentionsLegales() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Mentions Légales | Rock Your Net</title>
                <meta name="description" content="Mentions légales de Rock Your Net, agence web à Auxerre. Informations juridiques, conditions d'utilisation et politique de confidentialité de notre site." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="mentions légales, conditions utilisation, Rock Your Net Auxerre, politique confidentialité, informations juridiques" />
                <link rel="canonical" href="https://rockyournet.com/mentions-legales" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Mentions Légales | Rock Your Net" />
                <meta property="og:description" content="Consultez les mentions légales de Rock Your Net. Informations juridiques et conditions d'utilisation de notre agence web à Auxerre." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/mentions-legales" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Mentions Légales Rock Your Net" />
                <meta name="twitter:description" content="Informations légales et conditions d'utilisation de Rock Your Net, votre agence web à Auxerre." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Mentions Légales Rock Your Net" />

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Mentions Légales",
                        "url": "https://rockyournet.com/mentions-legales",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Mentions légales et informations juridiques de Rock Your Net, agence web basée à Auxerre.",
                        "publisher": {
                            "@type": "ProfessionalService",
                            "name": "Rock Your Net",
                            "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Auxerre",
                                "addressRegion": "Bourgogne-Franche-Comté",
                                "addressCountry": "FR"
                            },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5714"
                            }
                        },
                        "specialty": "Informations légales",
                        "mainContentOfPage": {
                            "@type": "WebPageElement",
                            "about": {
                                "@type": "Thing",
                                "name": "Informations juridiques et mentions légales"
                            }
                        }
                    }
                    `}
                </script>
            </Helmet>

            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr auto'}
                gridTemplateColumns='1fr'
                minHeight="100vh"
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3} w="100%">
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} pt={{ base: '65px', md: '79px' }} pb={8}>
                    <MainMentionsLégales />
                </GridItem>

                <GridItem area={'footer'} w="100%">
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};