import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainServices from '../sections/MainServices';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';

export default function Services() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Services Web & Digital à Auxerre | Création Sites, SEO, E-commerce | Rock Your Net</title>
                <meta name="description" content="Agence web complète à Auxerre : création de sites web, e-commerce, applications, SEO et marketing digital. Solutions sur mesure et accompagnement personnalisé pour développer votre présence en ligne en Bourgogne." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="agence web Auxerre, création sites web Bourgogne, développement web, SEO local, e-commerce, applications web, marketing digital, services numériques Auxerre" />
                <link rel="canonical" href="https://rockyournet.com/Services" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Services Web & Digital Auxerre | Rock Your Net" />
                <meta property="og:description" content="Votre partenaire digital à Auxerre : création de sites web, e-commerce, applications, SEO et stratégie digitale. Expertise locale pour votre succès en ligne." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Services" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Services Web & Digital Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Solutions web complètes à Auxerre : sites internet, e-commerce, applications, SEO et marketing digital. Expertise locale pour votre transformation digitale." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Services Web Rock Your Net Auxerre" />

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "ProfessionalService",
                        "name": "Rock Your Net - Services Web et Digital",
                        "url": "https://rockyournet.com/Services",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Agence web offrant des services complets de création web, marketing digital et SEO à Auxerre.",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "addressCountry": "FR"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "47.7979",
                            "longitude": "3.5714"
                        },
                        "telephone": "+33-6-24-31-10-75",
                        "priceRange": "€€",
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services Web",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Sites Web",
                                        "description": "Création de sites vitrines et e-commerce"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Applications Web",
                                        "description": "Développement d'applications web sur mesure"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "SEO",
                                        "description": "Optimisation du référencement naturel"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Community Management",
                                        "description": "Gestion des réseaux sociaux"
                                    }
                                }
                            ]
                        },
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        }
                    }
                    `}
                </script>
            </Helmet>

            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainServices />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};