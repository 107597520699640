import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainCréations from '../sections/MainCréations';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';

export default function Creations() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Créations Sites & Applications | Rock Your Net</title>
                <meta name="description" content="Découvrez nos réalisations web à Auxerre : sites vitrines, e-commerce et applications. Exemples de créations digitales sur mesure en Bourgogne. Expertise web locale." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="créations web Auxerre, sites internet, applications web Bourgogne, sites vitrines, e-commerce, projets digitaux, réalisations web" />
                <link rel="canonical" href="https://rockyournet.com/Créations" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Créations Sites & Applications | Rock Your Net" />
                <meta property="og:description" content="Explorez nos créations web à Auxerre : sites professionnels, boutiques en ligne et applications sur mesure. Découvrez notre expertise à travers nos projets." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Créations" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Créations Sites & Applications | Rock Your Net" />
                <meta name="twitter:description" content="Découvrez nos réalisations web à Auxerre. Sites internet, e-commerce et applications développés sur mesure pour nos clients en Bourgogne." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Créations Rock Your Net Auxerre" />

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Collection",
                        "name": "Créations Sites & Applications",
                        "url": "https://rockyournet.com/Créations",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Collection de nos réalisations web incluant des sites vitrines, e-commerce et applications développés sur mesure.",
                        "creator": {
                            "@type": "ProfessionalService",
                            "name": "Rock Your Net",
                            "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Auxerre",
                                "addressRegion": "Bourgogne-Franche-Comté",
                                "addressCountry": "FR"
                            },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5714"
                            },
                            "url": "https://rockyournet.com",
                            "telephone": "+33-6-24-31-10-75",
                            "priceRange": "€€"
                        },
                        "itemListElement": [
                            {
                                "@type": "CreativeWork",
                                "name": "Sites Vitrines",
                                "description": "Portfolio de sites web vitrines professionnels"
                            },
                            {
                                "@type": "CreativeWork",
                                "name": "E-commerce",
                                "description": "Réalisations de boutiques en ligne"
                            },
                            {
                                "@type": "CreativeWork",
                                "name": "Applications Web",
                                "description": "Développements d'applications sur mesure"
                            },
                            {
                                "@type": "CreativeWork",
                                "name": "Projets Spéciaux",
                                "description": "Solutions web personnalisées"
                            }
                        ],
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        }
                    }
                    `}
                </script>
            </Helmet>

            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainCréations />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};