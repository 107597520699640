import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainAPropos from '../sections/MainAPropos';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';

export default function APropos() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>À propos de Rock Your Net | Expert en Création Web et SEO</title>
                <meta name="description" content="Rock Your Net, votre agence web à Auxerre depuis 2020. Experte en création de sites web, applications et SEO. Une équipe locale passionnée au service de votre transformation digitale en Bourgogne." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="agence web Auxerre, Rock Your Net Bourgogne, expert création web, développement local, agence digitale Auxerre, expertise SEO, équipe web Bourgogne" />
                <link rel="canonical" href="https://rockyournet.com/A-propos" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="À propos de Rock Your Net | Agence Web Auxerre" />
                <meta property="og:description" content="Découvrez Rock Your Net, votre partenaire digital à Auxerre. Experts en création web, applications et SEO, nous accompagnons les entreprises de Bourgogne." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/A-propos" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="À propos de Rock Your Net | Agence Web Auxerre" />
                <meta name="twitter:description" content="Votre agence web locale à Auxerre. Expertise en création de sites, applications et SEO. Une équipe passionnée au service de votre succès digital." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Rock Your Net Auxerre - Agence Web" />

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "ProfessionalService",
                        "name": "Rock Your Net",
                        "url": "https://rockyournet.com",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Agence web spécialisée dans la création de sites web, le développement d'applications et l'optimisation SEO à Auxerre.",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "addressCountry": "FR"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "47.7979",
                            "longitude": "3.5714"
                        },
                        "url": "https://rockyournet.com",
                        "telephone": "+33-6-24-31-10-75",
                        "priceRange": "€€",
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        },
                        "openingHoursSpecification": [
                            {
                                "@type": "OpeningHoursSpecification",
                                "dayOfWeek": [
                                    "Monday",
                                    "Tuesday",
                                    "Wednesday",
                                    "Thursday",
                                    "Friday"
                                ],
                                "opens": "09:00",
                                "closes": "18:00"
                            }
                        ],
                        "knowsAbout": [
                            "Création de sites web",
                            "Développement d'applications",
                            "SEO",
                            "Marketing digital",
                            "E-commerce",
                            "Web design"
                        ],
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services Web",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Création de Sites Web",
                                        "description": "Développement de sites web professionnels"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "SEO",
                                        "description": "Optimisation pour les moteurs de recherche"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Applications Web",
                                        "description": "Développement d'applications sur mesure"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainAPropos />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};