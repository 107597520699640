import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainCommunityManagement from '../sections/MainCommunityManagement';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';

export default function CommunityManagementPage() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Services de Community Management à Auxerre | Rock Your Net</title>
                <meta name="description" content="Expert en Community Management à Auxerre. Gestion professionnelle de vos réseaux sociaux, création de contenu engageant et stratégies de marketing digital personnalisées pour développer votre présence en ligne." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="community management Auxerre, gestion réseaux sociaux, marketing digital Bourgogne, stratégie social media, création contenu, LinkedIn, Facebook, Instagram, analyse performance sociale" />
                <link rel="canonical" href="https://rockyournet.com/Services/Community-Management" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Expert Community Management Auxerre | Rock Your Net" />
                <meta property="og:description" content="Services professionnels de Community Management à Auxerre. Développez votre présence sur les réseaux sociaux et engagez votre communauté avec Rock Your Net." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Services/Community-Management" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Expert Community Management Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Développez votre présence sur les réseaux sociaux avec notre expertise en Community Management. Stratégies personnalisées et contenu engageant à Auxerre." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Community Management Rock Your Net Auxerre" />

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Community Management",
                        "name": "Services de Community Management",
                        "url": "https://rockyournet.com/Services/Community-Management",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Services professionnels de Community Management. Gestion des réseaux sociaux, création de contenu et stratégies de marketing digital personnalisées.",
                        "provider": {
                            "@type": "ProfessionalService",
                            "name": "Rock Your Net",
                            "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Auxerre",
                                "addressRegion": "Bourgogne-Franche-Comté",
                                "addressCountry": "FR"
                            },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5714"
                            },
                            "url": "https://rockyournet.com",
                            "telephone": "+33-6-24-31-10-75",
                            "priceRange": "€€"
                        },
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services de Community Management",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Gestion des Réseaux Sociaux",
                                        "description": "Gestion professionnelle de vos profils sociaux et création de contenu engageant"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Stratégie Social Media",
                                        "description": "Développement de stratégies personnalisées pour les réseaux sociaux"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Analyse et Reporting",
                                        "description": "Suivi des performances et analyse des résultats de vos campagnes sociales"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainCommunityManagement />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
}