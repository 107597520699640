import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainCreationSiteAuxerre from '../../sections/référencement/MainCreationSiteAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function CreationSiteAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Création de Site Internet à Auxerre | Sites Pro | Rock Your Net</title>
                <meta name="description" content="Expert en création de sites internet à Auxerre. Conception de sites web professionnels : sites vitrine, e-commerce et catalogues. Design responsive et optimisation SEO. Devis gratuit pour votre projet." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="création site internet Auxerre, site web professionnel 89, développeur web Auxerre, site vitrine Yonne, e-commerce Auxerre, webdesign local, site responsive" />
                <link rel="canonical" href="https://rockyournet.com/Creation-site-auxerre" />

                {/* Open Graph */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Création Sites Web Auxerre | Expert Développement Web" />
                <meta property="og:description" content="Votre expert en création de sites internet à Auxerre. Sites vitrines, e-commerce et catalogues professionnels. Design moderne et optimisation SEO inclus." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Creation-site-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Création Site Web Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Conception de sites internet professionnels à Auxerre. Expertise en développement web, responsive design et référencement naturel." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Création Sites Web Rock Your Net Auxerre" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "ProfessionalService",
                        "name": "Rock Your Net - Création Sites Web Auxerre",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Expert en création de sites internet professionnels à Auxerre. Développement web, responsive design et optimisation SEO pour votre entreprise.",
                        "url": "https://rockyournet.com/Creation-site-auxerre",
                        "telephone": "+33624311075",
                        "priceRange": "€€",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "addressCountry": "FR"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "47.7979",
                            "longitude": "3.5714"
                        },
                        "areaServed": [
                            {
                                "@type": "City",
                                "name": "Auxerre"
                            },
                            {
                                "@type": "AdministrativeArea",
                                "name": "Yonne"
                            }
                        ],
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday"
                            ],
                            "opens": "09:00",
                            "closes": "18:00"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services Création Web",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Sites Vitrines",
                                        "description": "Création de sites web vitrines professionnels"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "E-commerce",
                                        "description": "Développement de boutiques en ligne"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Sites Catalogues",
                                        "description": "Création de sites catalogue produits"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Optimisation SEO",
                                        "description": "Référencement naturel des sites web"
                                    }
                                }
                            ]
                        },
                        "knowsAbout": [
                            "Développement web",
                            "Webdesign",
                            "Sites responsive",
                            "SEO technique",
                            "WordPress",
                            "E-commerce"
                        ]
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainCreationSiteAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};