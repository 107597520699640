import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainCommunicationBourgogne from '../../sections/référencement/MainCommunicationBourgogne';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function CommunicationBourgogne() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Agence de Communication en Bourgogne | Stratégie Digitale | Rock Your Net</title>
                <meta name="description" content="Rock Your Net, agence de communication digitale pour toute la Bourgogne. Basée à Auxerre, nous accompagnons les entreprises régionales dans leur stratégie digitale : web, réseaux sociaux et marketing digital." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="agence communication Bourgogne, communication digitale BFC, stratégie web régionale, marketing digital Bourgogne-Franche-Comté, réseaux sociaux entreprise, communication Dijon, Auxerre, Nevers" />
                <link rel="canonical" href="https://rockyournet.com/Communication-bourgogne" />

                {/* Open Graph */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Agence Communication Bourgogne | Solutions Digitales Régionales" />
                <meta property="og:description" content="Votre partenaire communication en Bourgogne. Expertise digitale complète : stratégie web, réseaux sociaux et marketing digital pour les entreprises de la région." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Communication-bourgogne" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Communication Digitale Bourgogne | Rock Your Net" />
                <meta name="twitter:description" content="Solutions de communication régionales depuis Auxerre. Accompagnement digital personnalisé pour les entreprises de Bourgogne." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Communication Bourgogne Rock Your Net" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "ProfessionalService",
                        "name": "Rock Your Net - Communication Digitale Bourgogne",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Agence de communication digitale en Bourgogne, basée à Auxerre. Expertise en stratégie web, marketing digital et réseaux sociaux pour les entreprises régionales.",
                        "url": "https://rockyournet.com/Communication-bourgogne",
                        "telephone": "+33624311075",
                        "priceRange": "€€",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "addressCountry": "FR"
                        },
                        "geo": {
                            "@type": "GeoCircle",
                            "geoMidpoint": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5714"
                            },
                            "geoRadius": "150000"
                        },
                        "areaServed": [
                            {
                                "@type": "State",
                                "name": "Bourgogne-Franche-Comté"
                            },
                            {
                                "@type": "City",
                                "name": "Dijon"
                            },
                            {
                                "@type": "City",
                                "name": "Auxerre"
                            },
                            {
                                "@type": "City",
                                "name": "Nevers"
                            },
                            {
                                "@type": "City",
                                "name": "Sens"
                            }
                        ],
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday"
                            ],
                            "opens": "09:00",
                            "closes": "18:00"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services Communication Régionale",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Stratégie Digitale Régionale",
                                        "description": "Stratégies de communication adaptées au marché bourguignon"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Marketing Digital Local",
                                        "description": "Solutions marketing ciblées pour la Bourgogne"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Gestion Réseaux Sociaux",
                                        "description": "Animation des réseaux sociaux pour entreprises régionales"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Communication Web",
                                        "description": "Présence web optimisée pour la Bourgogne"
                                    }
                                }
                            ]
                        },
                        "knowsAbout": [
                            "Communication digitale régionale",
                            "Marketing digital Bourgogne",
                            "Stratégie web locale",
                            "Réseaux sociaux entreprise",
                            "Communication B2B",
                            "Marketing territorial"
                        ]
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainCommunicationBourgogne />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};