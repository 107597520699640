import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainContact from '../sections/MainContact';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';

export default function Contact() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Contactez-nous | Rock Your Net</title>
                <meta name="description" content="Contactez votre agence web à Auxerre. Discutons de vos projets de création de sites web, d'applications sur mesure ou d'optimisation SEO. Devis gratuit pour votre projet digital en Bourgogne." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="contact agence web Auxerre, devis site internet, projet web Bourgogne, création site web, développement application, SEO local, contact Rock Your Net" />
                <link rel="canonical" href="https://rockyournet.com/Contact" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Contactez-nous | Rock Your Net Auxerre" />
                <meta property="og:description" content="Discutons de votre projet web à Auxerre. Notre équipe est à votre écoute pour vos besoins en création de sites, applications et SEO. Demandez votre devis gratuit." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Contact" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Contactez Rock Your Net à Auxerre" />
                <meta name="twitter:description" content="Prenez contact avec notre équipe à Auxerre pour vos projets web. Expertise locale en création de sites, applications et SEO." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Contact Rock Your Net Auxerre" />

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "ContactPage",
                        "name": "Contact Rock Your Net",
                        "url": "https://rockyournet.com/Contact",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Page de contact de Rock Your Net, votre agence web à Auxerre.",
                        "mainEntity": {
                            "@type": "ProfessionalService",
                            "name": "Rock Your Net",
                            "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Auxerre",
                                "addressRegion": "Bourgogne-Franche-Comté",
                                "addressCountry": "FR"
                            },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5714"
                            },
                            "url": "https://rockyournet.com",
                            "telephone": "+33-6-24-31-10-75",
                            "priceRange": "€€",
                            "openingHoursSpecification": [
                                {
                                    "@type": "OpeningHoursSpecification",
                                    "dayOfWeek": [
                                        "Monday",
                                        "Tuesday",
                                        "Wednesday",
                                        "Thursday",
                                        "Friday"
                                    ],
                                    "opens": "09:00",
                                    "closes": "18:00"
                                }
                            ],
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": "+33-6-24-31-10-75",
                                "contactType": "customer service",
                                "areaServed": "FR",
                                "availableLanguage": "French",
                                "email": "contact@rockyournet.com"
                            }
                        }
                    }
                    `}
                </script>
            </Helmet>

            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                maxH='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative">
                    <MainContact />
                </GridItem>

                <GridItem area={'footer'} position="relative">
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};