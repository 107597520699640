import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainReferencement from '../sections/MainReferencement';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';

export default function Referencement() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Expert Référencement SEO à Auxerre | Optimisation Site Web | Rock Your Net</title>
                <meta name="description" content="Agence SEO à Auxerre spécialisée en référencement naturel. Audit SEO, optimisation technique, content marketing et stratégie de backlinks pour améliorer votre visibilité sur Google. Expertise locale en Bourgogne." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="référencement SEO Auxerre, agence SEO Bourgogne, optimisation site web, audit SEO, référencement naturel, positionnement Google, SEO local, stratégie digitale Auxerre" />
                <link rel="canonical" href="https://rockyournet.com/Services/Referencement" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Expert SEO Auxerre - Référencement Naturel | Rock Your Net" />
                <meta property="og:description" content="Optimisez votre visibilité en ligne avec notre expertise SEO à Auxerre. Audit complet, optimisation technique et stratégie de contenu personnalisée pour améliorer votre classement Google." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Services/Referencement" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Services SEO Professionnels Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Améliorez votre positionnement sur Google avec notre expertise SEO locale. Audit, optimisation et stratégie sur mesure pour votre entreprise à Auxerre." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Services SEO Rock Your Net Auxerre" />

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Search Engine Optimization",
                        "name": "Services de Référencement SEO",
                        "url": "https://rockyournet.com/Services/Referencement",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Services professionnels de référencement naturel et d'optimisation SEO pour améliorer votre visibilité sur les moteurs de recherche.",
                        "provider": {
                            "@type": "ProfessionalService",
                            "name": "Rock Your Net",
                            "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Auxerre",
                                "addressRegion": "Bourgogne-Franche-Comté",
                                "addressCountry": "FR"
                            },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5714"
                            },
                            "url": "https://rockyournet.com",
                            "telephone": "+33-6-24-31-10-75",
                            "priceRange": "€€"
                        },
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services SEO",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Audit SEO Complet",
                                        "description": "Analyse approfondie de votre site web et de son positionnement"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Optimisation Technique SEO",
                                        "description": "Amélioration des performances techniques de votre site"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Stratégie de Contenu SEO",
                                        "description": "Création de contenus optimisés pour le référencement"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "SEO Local",
                                        "description": "Optimisation de votre visibilité locale sur Google"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainReferencement />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};