import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../../common/Navbar';
import MainAuditSeoAuxerre from '../../sections/référencement/MainAuditSeoAuxerre';
import Footer from '../../common/Footer';
import { Helmet } from 'react-helmet';

export default function AuditSeoAuxerre() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                <title>Audit SEO à Auxerre | Analyse Site Web | Rock Your Net</title>
                <meta name="description" content="Expert en audit SEO à Auxerre. Analyse technique complète de votre site web : performance, visibilité locale, optimisation mobile et recommandations personnalisées. Devis gratuit pour votre audit SEO." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="audit SEO Auxerre, analyse site web 89, diagnostic référencement Yonne, audit technique SEO, analyse performance web, consultant SEO Auxerre, optimisation locale" />
                <link rel="canonical" href="https://rockyournet.com/Audit-seo-auxerre" />

                {/* Open Graph */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Audit SEO Professionnel Auxerre | Rock Your Net" />
                <meta property="og:description" content="Diagnostic SEO complet de votre site web à Auxerre. Analyse technique, référencement local et recommandations d'experts pour améliorer votre visibilité en ligne." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Audit-seo-auxerre" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Expert Audit SEO Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Audit SEO approfondi à Auxerre. Analyse technique et recommandations personnalisées pour optimiser votre présence en ligne." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Audit SEO Rock Your Net Auxerre" />

                {/* Schema.org */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "name": "Audit SEO Auxerre",
                        "serviceType": "SEO Analysis",
                        "description": "Service professionnel d'audit SEO et analyse technique de sites web à Auxerre",
                        "provider": {
                            "@type": "ProfessionalService",
                            "name": "Rock Your Net",
                            "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                            "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Auxerre",
                            "addressRegion": "Bourgogne-Franche-Comté",
                            "addressCountry": "FR"
                        },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5714"
                            },
                            "telephone": "+33624311075",
                            "openingHoursSpecification": {
                                "@type": "OpeningHoursSpecification",
                                "dayOfWeek": [
                                    "Monday",
                                    "Tuesday",
                                    "Wednesday",
                                    "Thursday",
                                    "Friday"
                                ],
                                "opens": "09:00",
                                "closes": "18:00"
                            }
                        },
                        "areaServed": [
                            {
                                "@type": "City",
                                "name": "Auxerre"
                            },
                            {
                                "@type": "AdministrativeArea",
                                "name": "Yonne"
                            }
                        ],
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services Audit SEO",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Audit SEO Technique",
                                        "description": "Analyse complète des aspects techniques de votre site"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Audit SEO Local",
                                        "description": "Analyse de votre visibilité locale sur Google"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Audit Performance Web",
                                        "description": "Analyse des performances et de l'expérience utilisateur"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Recommandations SEO",
                                        "description": "Plan d'action détaillé pour améliorer votre référencement"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainAuditSeoAuxerre />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};