import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainSiteECommerce from '../sections/MainSiteECommerce';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';

export default function SiteECommerce() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Création Site E-commerce à Auxerre | Boutique en Ligne | Rock Your Net</title>
                <meta name="description" content="Expert en création de sites e-commerce à Auxerre. Solutions de boutique en ligne personnalisées, paiement sécurisé, design responsive et optimisation SEO. Développez votre commerce en ligne en Bourgogne." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="création site e-commerce Auxerre, boutique en ligne Bourgogne, développement e-commerce, WooCommerce, PrestaShop, paiement sécurisé, site marchand, vente en ligne Auxerre" />
                <link rel="canonical" href="https://rockyournet.com/Services/Site-e-commerce" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Création Site E-commerce Auxerre | Expert Boutique en Ligne" />
                <meta property="og:description" content="Créez votre boutique en ligne performante à Auxerre. Solutions e-commerce sur mesure, paiement sécurisé et design responsive. Expertise locale en Bourgogne." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Services/Site-e-commerce" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Expert E-commerce Auxerre | Création Boutique en Ligne" />
                <meta name="twitter:description" content="Solutions e-commerce professionnelles à Auxerre. Boutiques en ligne performantes, paiement sécurisé et design adaptatif pour votre commerce." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Création E-commerce Rock Your Net Auxerre" />

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "E-commerce Development",
                        "name": "Création de Sites E-commerce",
                        "url": "https://rockyournet.com/Services/Site-e-commerce",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Service professionnel de création de sites e-commerce. Solutions personnalisées incluant design responsive, paiement sécurisé et optimisation SEO.",
                        "provider": {
                            "@type": "ProfessionalService",
                            "name": "Rock Your Net",
                            "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Auxerre",
                                "addressRegion": "Bourgogne-Franche-Comté",
                                "addressCountry": "FR"
                            },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5714"
                            },
                            "url": "https://rockyournet.com",
                            "telephone": "+33-6-24-31-10-75",
                            "priceRange": "€€"
                        },
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services E-commerce",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Création Boutique en Ligne",
                                        "description": "Développement de boutiques en ligne personnalisées et performantes"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Solutions de Paiement",
                                        "description": "Intégration de solutions de paiement sécurisées et fiables"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Design E-commerce",
                                        "description": "Création d'interfaces utilisateur optimisées pour la conversion"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Optimisation E-commerce",
                                        "description": "SEO et performance pour boutiques en ligne"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainSiteECommerce />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};