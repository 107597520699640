import React from 'react';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import Navbar from '../common/Navbar';
import MainSiteVitrine from '../sections/MainSiteVitrine';
import Footer from '../common/Footer';
import { Helmet } from 'react-helmet';

export default function SiteVitrine() {
    const bg = useColorModeValue('gray.50', 'gray.900');

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Création Site Vitrine à Auxerre | Site Web Professionnel | Rock Your Net</title>
                <meta name="description" content="Expert en création de sites vitrines à Auxerre. Conception de sites web modernes et responsives, optimisés SEO. Solutions sur mesure pour les professionnels en Bourgogne. Devis gratuit pour votre projet." />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta name="keywords" content="création site vitrine Auxerre, site web professionnel Bourgogne, création site internet, développement web Auxerre, site responsive, site entreprise, agence web locale" />
                <link rel="canonical" href="https://rockyournet.com/Services/Site-vitrine" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:site_name" content="Rock Your Net" />
                <meta property="og:title" content="Création Site Vitrine Professionnel Auxerre | Rock Your Net" />
                <meta property="og:description" content="Expert en création de sites vitrines professionnels à Auxerre. Design moderne, responsive et optimisé SEO. Développez votre présence en ligne avec Rock Your Net." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://rockyournet.com/Services/Site-vitrine" />
                <meta property="og:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="fr_FR" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Expert Site Vitrine Auxerre | Rock Your Net" />
                <meta name="twitter:description" content="Création de sites web vitrines professionnels à Auxerre. Design moderne, responsive et optimisé pour votre visibilité en ligne." />
                <meta name="twitter:image" content="https://rockyournet.com/static/media/logorockyournet2.jpg" />
                <meta name="twitter:image:alt" content="Création Site Vitrine Rock Your Net Auxerre" />

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Website Development",
                        "name": "Création de Sites Vitrines Professionnels",
                        "url": "https://rockyournet.com/Services/Site-vitrine",
                        "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                        "description": "Service professionnel de création de sites vitrines. Design moderne, responsive et optimisé pour les moteurs de recherche.",
                        "provider": {
                            "@type": "ProfessionalService",
                            "name": "Rock Your Net",
                            "image": "https://rockyournet.com/static/media/logorockyournet2.jpg",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Auxerre",
                                "addressRegion": "Bourgogne-Franche-Comté",
                                "addressCountry": "FR"
                            },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": "47.7979",
                                "longitude": "3.5714"
                            },
                            "url": "https://rockyournet.com",
                            "telephone": "+33-6-24-31-10-75",
                            "priceRange": "€€"
                        },
                        "areaServed": {
                            "@type": "City",
                            "name": "Auxerre"
                        },
                        "hasOfferCatalog": {
                            "@type": "OfferCatalog",
                            "name": "Services Sites Vitrines",
                            "itemListElement": [
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Site Vitrine sur Mesure",
                                        "description": "Création de sites web personnalisés selon vos besoins"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Design Responsive",
                                        "description": "Sites web adaptés à tous les appareils"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Optimisation SEO",
                                        "description": "Référencement naturel pour une meilleure visibilité"
                                    }
                                },
                                {
                                    "@type": "Offer",
                                    "itemOffered": {
                                        "@type": "Service",
                                        "name": "Maintenance Web",
                                        "description": "Suivi et mises à jour de votre site vitrine"
                                    }
                                }
                            ]
                        }
                    }
                    `}
                </script>
            </Helmet>

            {/* Structure de la page */}
            <Grid
                templateAreas={`"header header" "main main" "footer footer"`}
                gridTemplateRows={'auto 1fr 50px'}
                gridTemplateColumns='1fr'
                h='100vh'
                color='blackAlpha.700'
                fontWeight='bold'
                bg={bg}
            >
                <GridItem area={'header'} position="fixed" zIndex={3}>
                    <Navbar />
                </GridItem>

                <GridItem area={'main'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <MainSiteVitrine />
                </GridItem>

                <GridItem area={'footer'} position="relative" top={{ base: '65px', md: '79px' }}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};